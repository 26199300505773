const Bbold_bed = [
	{
		id: 1,
		path: "bed/REEFBREAK",
		title: "Reefbreak",
		file: require("../../../assets/Files/collection/bbold/BedsFrames.pdf"),
		photo: [
			require("../../../assets/images/collection/bbold/Beds/reefbreak/reefbreak_1.JPG"),
			require("../../../assets/images/collection/bbold/Beds/reefbreak/reefbreak_2.JPG"),
			require("../../../assets/images/collection/bbold/Beds/reefbreak/reefbreak_3.JPG"),
			require("../../../assets/images/collection/bbold/Beds/reefbreak/reefbreak_4.JPG"),
		]
	},
	
]

export default Bbold_bed;